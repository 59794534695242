import { useFormik } from "formik";
import React, { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { Button, Form, Grid, Header, Message, Segment } from "semantic-ui-react";
import AppContext from "../contexts/AppContext";
import {
    VaccineDateQuestion,
    JurisdictionQuestion,
    VaccineReceivedQuestion,
    IsRepresentativeQuestion,
    ApptTypeQuestion,
    VaccineICQuestion,
    AdditionalDoseScheduledQuestion,
    VaccineNameQuestionPediatric,
    GeneralAppointmentReason,
    ICQuestion,
    VaccineNameQuestionBooster,
    WhichDosePedsQuestion,
    PediatricICQuestion,
    GeneralAppointmentText,
    CovidAge,
    AtRiskGeneral,
    WhichRoutineDose,
    MpoxDose,
    WhichMpoxVaccine,
    CoadminFlue,
    TestLabQuestion,
} from "./base/Questions";
import timeRangeValidation from "../util/timeRangeValidation";
import { isInvalidMMR } from "../util/mmrTimeRangeValidation";
import * as Yup from "yup";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { UrlHelper } from "../shared/url.helper";
import ReactMarkdown from "react-markdown";
import { getVaccinationInfoByLocation } from "../services/VaccineInfoByLocationfromSF";

type DoseType = "booster" | "1" | "additional" | "2" | ""
interface FormData {
    jurisdiction: string;
    doseType: "booster_2" | DoseType;
    pediatricQuestionDoseType: DoseType;
    vaccineReceived: "yes" | "no" | "";
    isRepresentative: "yes" | "no" | "";
    vaccineName: string;
    vaccineDate: Date;
    attestationChecked: boolean;
    apptType: string;
    vaccineICName: string;
    covidAge: "adult" | "minor" | "toddler" | "";
    pediatricICQuestion: "yes" | "no" | "";
    questionIC: "yes" | "no" | "dont-know" | "";
    whichDose: "1" | "2" | "3" | "4" | "dont-know" | "";
    whichRoutineDose: string;
    boosterNumber: "1" | "2" | "3" | "4" | ""
    generalAppointmentReason: string,
    generalAppointmentText: string
    dobQuestion: string
    atRiskGeneral: "yes" | "no" | "dont-know" | "";
    mpoxDose: "1" | "2" | "dont-know" | ""
    whichMpoxVaccine: "" | "dont-remember" | "JYNNEOS" | "ACAM2000"
    coadminFlu: "yes" | "no" | ""
    testLabGroupQuestion: string
}

export function GuestForm() {
    const history = useHistory();
    const [disabled, setDisabled] = useState(false);

    console.log(`USERHISTORY : ${JSON.stringify(history)}`)

    const queryParameters = new URLSearchParams(window.location.search)
    const locationID = queryParameters.get("locationID")
    const clinicType = queryParameters.get("clinicType")
    console.log(`Location ID: ${locationID}`)
    console.log(`clinicType ID: ${clinicType}`)

    const [locationId, setLocationID] = useState(locationID);
    const [clinicTypeID, setclinicType] = useState(clinicType);
    const [isInfluenza, setIsInfluenza] = useState(true)
    const [appointmentType, setAppointmentType] = useState('')


    const [message, setMessage] = useState<{
        header: string;
        body: string;
        confirmation?: string;
        showOverwrite?: boolean;
        error?: boolean;
        success?: boolean;
        warning?: boolean;
    }>(null);

    const ctx = useContext(AppContext);

    const isRoutineVaccine = (vaccine: string) => ["", "COVID", "COVID-19 testing", "Influenza", "Routine vaccine", "general", "Mpox vaccine", "Respiratory Syncytial Virus (RSV) vaccine"].indexOf(vaccine) < 0

    useEffect(() => {
        const fetchOptions = async () => {
            if(locationID){
                try{
                    const options = await getVaccinationInfoByLocation(ctx.apiUrl, locationId);
                    console.log(`fetched Data - ${JSON.stringify(options)}`)
                    if(options && options.clinicQrCodeOption){
                        setAppointmentType(options.clinicQrCodeOption)
                    }
                    if(options && options.vaccineCarriedList){
                        const checkInfluenza = options.vaccineCarriedList.filter((option: any) => option.value === 'Influenza')
                        if(checkInfluenza.length === 0 ){
                            setIsInfluenza(false)
                        }else{
                            setIsInfluenza(true)
                        }
                    }
                } catch(error) {
                    console.error('Failed to fetch Options:', error)
                }
            }
        }
        fetchOptions();
    }, [ctx.apiUrl, locationId])
    
    const onSubmit = async (values: FormData) => {
        console.log(`vlaues - ${JSON.stringify(values)}`)
        try {
            setDisabled(true);
            setMessage(null);
            //look into this
            const vaccineType = values.vaccineICName
            // const vaccineType = values.vaccineICName.map((vaccine) => vaccine === "COVID" ? "COVID-19" : vaccine).join("|");

            if (values.vaccineName === "janssen" && (values.vaccineReceived === "yes" || values.doseType==="2" )) {
                const qStr = UrlHelper.upsertParams({
                    vaccinename: values.vaccineName,
                    vaccineType,
                });
                history.push(`/singleDoseVaccine${qStr}`);
                return;
            }

            // Send them to the right url for apptType
            debugger
            let url
            if(ctx.isvamsrefresh) {
                url = new URL(
                    values.apptType === "preregister" || ctx.clinicType.toLocaleLowerCase() === "tp"
                        ? ctx.config.manageApptsUrl
                        : ctx.config.guestUrlvamsrefresh
                );
            }
            else {
                url = new URL(
                    values.apptType === "preregister" || ctx.clinicType.toLocaleLowerCase() === "tp" || appointmentType?.includes('walk-in')
                        ? ctx.config.manageApptsUrl
                        : ctx.config.guestUrl
                );
            }
           // test
            url.searchParams.append("language", ctx.lang === "en" ? "en_US" : ctx.lang);

            // Only send clinicID if its present
            if (ctx.clinicID) {
                url.searchParams.append("clinicID", ctx.clinicID);
            }

            // Only send locationID if its present
            if (ctx.locationID) {
                url.searchParams.append("locationID", ctx.locationID);
            }

            // Configure redirct for prereg
            if (values.apptType === "preregister" || ctx.clinicType.toLocaleLowerCase() === "tp" || appointmentType?.includes('walk-in')) {
                url.searchParams.append("redirect", "registerforwalkin");
            }

            if (values.vaccineICName === "general") {
                if (values.generalAppointmentReason === 'Other') {
                    url.searchParams.append("generalAppointmentText", values.generalAppointmentText);
                } else {
                    url.searchParams.append("generalAppointmentText", values.generalAppointmentReason);
                }


                if (values.generalAppointmentReason === 'COVID-19 testing') {
                    url.searchParams.append("vaccineType", "COVID-19 testing");

                    let doseType = "";
                    switch (values.testLabGroupQuestion) {
                        case "DLT":
                            {
                                doseType = "Diagnostic Lab Test- PCR (Molecular-PCR SARS-CoV2)";
                                break
                            }
                        case "RDT":
                            {
                                doseType = "Rapid Diagnostic Test (IDNow Rapid COVID-19 PCR)";
                                break
                            }
                        case "RAT":
                            {

                                doseType = "Rapid Antigen Test (BinaxNOW Rapid COVID-19 Antigen)";
                                break
                            }
                        case "SST":
                            {
                                doseType = "COVID-19 Antibody (SST)";
                                break
                            }
                    }

                    url.searchParams.append("doseType", doseType);
                } else {
                    url.searchParams.append("vaccineType", "General appointment");
                    url.searchParams.append("doseType", "general");

                }

                url.searchParams.append("jurisdiction", values.jurisdiction);


                window.location.href = url.toString();
                return
            }

            if (values.vaccineICName === "Mpox vaccine") {
                url.searchParams.append("doseType", "mpox");
                url.searchParams.append("vaccineType", "Monkeypox vaccine");
                url.searchParams.append("jurisdiction", values.jurisdiction);
                url.searchParams.append("dose", values.mpoxDose !== '' ? values.mpoxDose : 'dont-know');

                if (values.mpoxDose === "2") {
                    url.searchParams.append("vaccinename", values.whichMpoxVaccine);
                    if(values.vaccineDate) url.searchParams.append("initialvaccinationdate", moment(values.vaccineDate).format("L"));
                }

                window.location.href = url.toString();
                return
            }

            if (values.vaccineICName !== "COVID") {
                let dose = values.whichRoutineDose !== "" ? values.whichRoutineDose : "dont-know"

                if (values.vaccineICName === 'Influenza') {
                    dose = "1"
                }

                if (values.vaccineICName === 'Respiratory Syncytial Virus (RSV) vaccine') {
                    dose = "1"
                }

                url.searchParams.append("jurisdiction", values.jurisdiction)
                url.searchParams.append("vaccineType", "Routine");
                url.searchParams.append("doseType", values.vaccineICName);
                url.searchParams.append("dose", dose);
                url.searchParams.append("atrisk", values.atRiskGeneral !== "" ? values.atRiskGeneral : "dont-know")

                window.location.href = url.toString();
                return
            }

            let dose: number | string = 1

            if (values.vaccineReceived === "yes") {
                dose = 2
            }

            let doseType = values.covidAge === "adult" ?
                values.doseType :
                values.whichDose

            if (doseType === "" || doseType === "dont-know") {
                doseType = "dont-know"
                dose = "dont-know"
            }

            // Make this from new question
            if (values.covidAge !== "adult") {
                dose = !Number.isNaN(parseInt(values.whichDose)) ?
                    parseInt(values.whichDose) :
                    3
            }

            if (values.covidAge !== "adult" && (values.whichDose === '' || values.whichDose === 'dont-know')) {
                dose = 'dont-know'
            }

            if(values.covidAge === "adult" && doseType === "2"){
                dose = 2
            }

            if (doseType === "3") {
                dose = 3
            }

            if (values.covidAge === "adult") {
                if (doseType === "4") {
                    dose = parseInt(values.boosterNumber) + 2
                }
            } else {
                if (doseType === "3") {
                    dose = 3
                }

                if (doseType === "4") {
                    dose = 4
                }

                if (doseType === "booster_2") {
                    dose = 4
                }
            }


            url.searchParams.append("jurisdiction", values.jurisdiction);

            url.searchParams.append("vaccineType", vaccineType);

            if (values.coadminFlu === "yes") {
                url.searchParams.append("vaccineType", `${vaccineType}|Routine`);
            }

            if (values.covidAge === "minor" && values.vaccineICName === "COVID") {
                url.searchParams.append("ageCategory", "minor");
            }

            if (values.covidAge === "toddler" && values.vaccineICName === "COVID") {
                url.searchParams.append("ageCategory", "toddler");
            }

            // url.searchParams.append("isRepresentative", values.isRepresentative);
            // t

            if (values.covidAge === "adult" && doseType === "4") {
                url.searchParams.append("vaccinename", values.vaccineName);
                if(values.vaccineDate) url.searchParams.append("initialvaccinationdate", moment(values.vaccineDate).format("L"));
                url.searchParams.append("dose", "4");
            } else {
                url.searchParams.append("dose", String(dose));
            }

            // Only send vaccine info if we're on dose 2 or more
            if (!isNaN(Number(dose)) && Number(dose) >= 2) {
                url.searchParams.append("vaccinename", values.vaccineName);
                if(values.vaccineDate) url.searchParams.append("initialvaccinationdate", moment(values.vaccineDate).format("L"));
            }

            url.searchParams.append("IC", values.questionIC !== "" ? values.questionIC : 'dont-know');
            //url.searchParams.append("language", ctx.lang === "en" ? "en_USABC" : ctx.lang);

            if (doseType === "3" && values.covidAge === "toddler") {
                url.searchParams.append("doseType", '3');
            }

            if (doseType.substring(0, 7) === "4" && values.covidAge === "toddler") {
                url.searchParams.append("IC", values.pediatricICQuestion);

                url.searchParams.append("dose", "4");
            }
            if (doseType === "3" || doseType.substring(0, 7) === "4") {
                url.searchParams.append("doseType", doseType);
            }

            window.location.href = url.toString();
        } catch (error) {
            console.log(error);
            // @ts-ignore
            setMessage({ error: true, header: "Error", body: error.message });
            setDisabled(false);
        }
    };

    const defaultValues = (): FormData => {
        return {
            jurisdiction: ctx.jurisdiction.id,
            pediatricICQuestion: "",
            pediatricQuestionDoseType: "",
            doseType: "",
            vaccineReceived: "",
            isRepresentative: "",
            vaccineName: "",
            vaccineDate: undefined,
            attestationChecked: false,
            apptType: "",
            // vaccineICName: ["adult", "COVID"],
            vaccineICName: "",
            covidAge: "",
            questionIC: "",
            whichDose: "",
            boosterNumber: "",
            generalAppointmentReason: "",
            generalAppointmentText: "",
            dobQuestion: "",
            atRiskGeneral: "",
            whichRoutineDose: "",
            mpoxDose: "",
            whichMpoxVaccine: "",
            coadminFlu: "",
            testLabGroupQuestion: ""
        };
    };

    const formik = useFormik({
        initialValues: defaultValues(),
        validationSchema: Yup.object({
            jurisdiction: Yup.string().required(ctx.getLabel("requiredFieldError")),
            vaccineICName: Yup.string().required(ctx.getLabel("requiredFieldError")),
            doseType: Yup.string().when("vaccineICName", {
                is: "COVID",
                then: Yup.string().when("covidAge", {
                    is: "adult",
                    then: Yup.string().required(ctx.getLabel("requiredFieldError"))
                }),
            }),
            whichDose: Yup.string().when("vaccineICName", {
                is: "COVID",
                then: Yup.string().when("covidAge", {
                    is: (value: string) => value !== "adult",
                    then: Yup.string().required(ctx.getLabel("requiredFieldError"))
                }),
            }),
            // whichRoutineDose: Yup.string().when("vaccineICName", {
            //     is: (value: string) => ["Dengue vaccine", "Diphtheria, Tetanus & Acellular Pertussis (DTaP/Tdap) vaccine",
            //         "Hepatitis A vaccine", "Hepatitis B vaccine", "Human Papillomavirus (HPV) vaccine",
            //         "Measles, Mumps, and Rubella (MMR) vaccine", "Meningococcal ACWY vaccine", "Meningococcal B vaccine",
            //         "Pneumococcal Conjugate vaccine", "Pneumococcal Polysaccharide vaccine", "Polio vaccine",
            //         "Recombinant Zoster (Shingles) vaccine", "Rotavirus vaccine", "Varicella (Chickenpox) vaccine"].includes(value),
            //     then: Yup.string().required(ctx.getLabel("requiredFieldError"))
            // }),
            mpoxDose: Yup.string().when("vaccineICName", {
                is: "Mpox vaccine",
                then: Yup.string().required(ctx.getLabel("requiredFieldError"))
            }),
            pediatricQuestion: Yup.string().when("vaccineICName", {
                is: "COVID",
                then: Yup.string().when("covidAge", {
                    is: (value: string) => value !== "adult",
                    then: Yup.string().when("pediatricQuestionAdditionalDose", {
                        is: "no",
                        then: Yup.string().required(ctx.getLabel("requiredFieldError"))
                    }),
                }),
            }),
            covidAge: Yup.string().when("vaccineICName", {
                is: "COVID",
                then: Yup.string().required(ctx.getLabel("requiredFieldError"))
            }),
            coadminFlu: isInfluenza && Yup.string().when("vaccineICName", {
                is: "COVID",
                then: Yup.string().required(ctx.getLabel("requiredFieldError"))
            }),
            // vaccineReceived: Yup.string().when("vaccineICName", {
            //     is: "COVID",
            //     then: Yup.string().when("covidAge", {
            //         is: "adult",
            //         then: Yup.string().when("doseType", {
            //             is: "no",
            //             then: Yup.string().required(ctx.getLabel("requiredFieldError")),
            //         }),
            //     }),
            // }),
            vaccineName: Yup.string()
                .when("vaccineICName", {
                    is: "COVID",
                    then: Yup.string()
                        
                        .when("covidAge", {
                                is: "adult",
                                then: Yup.string()
                                    // .when("vaccineReceived", {
                                    //     is: "yes",
                                    //     then: Yup.string().required(ctx.getLabel("vaccineNameRequiredError")),
                                    // })
                                    .when("doseType", {
                                        is: "2",
                                        then: Yup.string().required(ctx.getLabel("vaccineNameRequiredError")),
                                    })
                                    .when("doseType", {
                                        is: "3",
                                        then: Yup.string().required(ctx.getLabel("vaccineNameRequiredError")),
                                    })
                                    .when("doseType", {
                                        is: (value: string) => value?.substring(0, 7) === "4",
                                        then: Yup.string().required(ctx.getLabel("vaccineNameRequiredError")),
                                    }),
                            })
                        .when("covidAge", {
                            is: "minor",
                            then: Yup.string()
                                .when("whichDose",{
                                    is: (value : string) => ["4","dont-know"].includes(value),
                                    then: Yup.string().required(ctx.getLabel("vaccineNameRequiredError")),
                                })
                        })
                        .when("covidAge", {
                            is: "toddler",
                            then: Yup.string()
                                .when("whichDose",{
                                    is: (value : string) => ["2","3", "4", "dont-know"].includes(value),
                                    then: Yup.string().required(ctx.getLabel("vaccineNameRequiredError")),
                                })
                        })                        .when("covidAge", {
                            is: "minor",
                            then: Yup.string()
                                .when("whichDose",{
                                    is: (value : string) => ["4","dont-know"].includes(value),
                                    then: Yup.string().required(ctx.getLabel("vaccineNameRequiredError")),
                                })
                        })
                        .when("covidAge", {
                            is: "toddler",
                            then: Yup.string()
                                .when("whichDose",{
                                    is: (value : string) => ["2","3", "4", "dont-know"].includes(value),
                                    then: Yup.string().required(ctx.getLabel("vaccineNameRequiredError")),
                                })
                        })
                }),
                vaccineDate: Yup.string()
                .when("vaccineICName", {
                    is: "COVID",
                    then: Yup.string()
                        .when("covidAge", {
                            is: "adult",
                            // then: Yup.string()
                            //     .when("vaccineReceived", {
                            //         is: "yes",
                            //         then: Yup.string().required(ctx.getLabel("vaccineDateRequiredError")).nullable(),
                            //     })
                            then:Yup.string()
                                .when("doseType", {
                                    is: "2",
                                    then: Yup.string()
                                    .when("vaccineName", {
                                        is: (value: string) => value !== "dont-remember",
                                        then: Yup.string().required(ctx.getLabel("vaccineDateRequiredError")),
                                    }),
                                })
                                .when("doseType", {
                                    is: "3",
                                    then: Yup.string()
                                    .when("vaccineName", {
                                        is: (value: string) => value !== "dont-remember",
                                        then: Yup.string().required(ctx.getLabel("vaccineDateRequiredError")).nullable(),
                                    }),
                                })
                                .when("doseType", {
                                    is: (value: string) => value?.substring(0, 7) === "4",
                                    then: Yup.string()
                                    .when("vaccineName", {
                                        is: (value: string) => value !== "dont-remember",
                                        then: Yup.string().required(ctx.getLabel("vaccineDateRequiredError")).nullable(),
                                    }),
                                })
                        })
                        .when("covidAge", {
                            is: "minor",
                            then: Yup.string()
                                .when("whichDose", {
                                    is: "4",
                                    then: Yup.string()
                                    .when("vaccineName", {
                                        is: (value: string) => value !== "dont-remember",
                                        then: Yup.string().required(ctx.getLabel("vaccineDateRequiredError")).nullable(),
                                    }),
                                })
                            })
                        .when("covidAge", {
                            is: "toddler",
                            then: Yup.string()
                                .when("whichDose", {
                                    is: (value: string) => ["2","3","4"].includes(value),
                                    then: Yup.string()
                                    .when("vaccineName", {
                                        is: (value: string) => value !== "dont-remember",
                                        then: Yup.string().required(ctx.getLabel("vaccineDateRequiredError")).nullable(),
                                    }),
                                })
                        }),
                })
                .when("vaccineICName", {
                    is: "Mpox vaccine",
                    then: Yup.string().when("mpoxDose", {
                        is: (val: string) => val === '2',
                        then: Yup.string().required(ctx.getLabel("vaccineDateRequiredError")).nullable(),
                    })
                }),
            generalAppointmentReason: Yup.string().when("vaccineICName", {
                is: "general",
                then: Yup.string().required(ctx.getLabel("requiredFieldError"))
            }),
            generalAppointmentText: Yup.string().when("generalAppointmentReason", {
                is: 'Other',
                then: Yup.string().required().max(20)
            }),
            attestationChecked: Yup.boolean().required(ctx.getLabel("requiredFieldError")),
            apptType: ctx.clinicType.toLocaleLowerCase() !== "tp" && !(appointmentType && appointmentType !== "Allow both") && Yup.string().test("apptTypeRequired", ctx.getLabel("requiredFieldError"), (value) => {
                if (ctx.clinicID === null) return true;
                return value !== undefined;
            }),
            whichMpoxVaccine: Yup.string().when("vaccineICName", {
                is: 'Mpox vaccine',
                then: Yup.string().when("mpoxDose", {
                    is: "2",
                    then: Yup.string().required(ctx.getLabel("requiredFieldError"))
                })
                    .matches(/^(?!ACAM2000).*$/, {
                        message: ctx.getLabel("acamError")
                    }),
            }),
        }),
        onSubmit: onSubmit,
    });

    const doseType = formik.values.covidAge === "adult" ?
        formik.values.doseType :
        formik.values.whichDose

    const attestationText = () => {
        switch (doseType) {
            case "additional":
                return ctx.getLabel("attestationAdditionalDose");
            case "4":
                return ctx.getLabel("attestationBoosterlDose");
            default:
                return ctx.getLabel("attestation");
        }
    };

    const notAdult = () => formik.values.covidAge === "minor" || formik.values.covidAge === "toddler"

    console.log(formik.errors)
    const Welcomelbl = ctx.getLabel("requiredNote").split(" * ")

    

    return (
        <Grid container>
            <Grid.Row>
                <Grid.Column>
                    <Header as="h1">{ctx.getJurisdictionLabel("welcome")}</Header>
                    <span>
                        {Welcomelbl[0]}
                        <span style={{ color: 'red' }}>{' * '}</span>
                        {Welcomelbl[1]}
                    </span>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Form success className="eligibility-form" onSubmit={formik.handleSubmit}>
                        <Grid columns={1}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Segment padded>
                                        <Grid columns={1}>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    {ctx.config.getJurisdictionList().findIndex((j) => j.value === ctx.jurisdiction.id) === -1 && (
                                                        <JurisdictionQuestion
                                                            formik={formik as any}
                                                            disabled={disabled}
                                                            tabIndex={1}
                                                        />
                                                    )}
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <VaccineICQuestion
                                                        formik={formik as any}
                                                        disabled={disabled}
                                                        tabIndex={2}
                                                        locationID={locationId}
                                                        clinicTypeID={clinicTypeID}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            {
                                                ["", "COVID", "general", "COVID-19 testing", "Influenza", "Routine vaccine", "Mpox vaccine"].indexOf(formik.values.vaccineICName) < 0 &&
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Message icon="info circle" content={
                                                            <>
                                                                {ctx.getLabel('childrenVaccines')}
                                                                <ul>
                                                                    <li> <ReactMarkdown linkTarget="_blank">{ctx.getLabel('childrenVaccines18')}</ReactMarkdown></li>
                                                                    <li> <ReactMarkdown linkTarget="_blank">{ctx.getLabel('childrenVaccines19')}</ReactMarkdown></li>
                                                                </ul>
                                                            </>
                                                        } />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            }

                                            {(formik.values.vaccineICName === "COVID") &&
                                                <>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <CovidAge
                                                                formik={formik as any}
                                                                tabIndex={3}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    {isInfluenza &&
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <CoadminFlue
                                                                formik={formik as any}
                                                                tabIndex={3}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>}
                                                </>
                                            }
                                            {(
                                                formik.values.covidAge === "minor" ||
                                                formik.values.covidAge === "toddler"
                                            ) &&
                                                formik.values.vaccineICName === "COVID" && (
                                                    <>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <WhichDosePedsQuestion
                                                                    formik={formik as any}
                                                                    tabIndex={3}
                                                                />
                                                            </Grid.Column>
                                                        </Grid.Row>

                                                    </>
                                                )}

                                            {formik.values.vaccineICName === "COVID" &&
                                                formik.values.covidAge === "adult" && (
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <AdditionalDoseScheduledQuestion
                                                                formik={formik as any}
                                                                disabled={disabled}
                                                                tabIndex={4}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                )}
                                            {doseType.substring(0, 7) === "4" &&
                                                formik.values.covidAge === "adult" ? (
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <ICQuestion
                                                            formik={formik as any}
                                                            disabled={disabled}
                                                            tabIndex={3}
                                                            hideNote={(doseType) !== "4"}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            ) : null}
                                            {/* {doseType === "no" &&
                                                (
                                                    formik.values.vaccineICName === "COVID" &&
                                                    formik.values.covidAge === "adult" 
                                                ) && (
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <VaccineReceivedQuestion
                                                                formik={formik as any}
                                                                disabled={disabled}
                                                                tabIndex={5}
                                                                adult={formik.values.covidAge === "adult"}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                )} */}

                                            {
                                                (
                                                    ["additional", "4", "3"].indexOf(formik.values.whichDose) >= 0 &&
                                                    (
                                                        formik.values.covidAge === "minor" ||
                                                        formik.values.covidAge === "toddler"
                                                    )
                                                ) && (
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <PediatricICQuestion
                                                                formik={formik as any}
                                                                disabled={disabled}
                                                                tabIndex={5}
                                                                adult={!notAdult()}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                )}
                                            {(doseType === "2" ||
                                                doseType.substring(0, 7) === "4" ||
                                                doseType === "3") &&
                                                formik.values.vaccineICName === "COVID" &&
                                                formik.values.covidAge === "adult" && (
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <VaccineNameQuestionBooster
                                                                formik={formik as any}
                                                                disabled={disabled}
                                                                tabIndex={7}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                )}
                                            {(notAdult() &&
                                                (formik.values.whichDose && formik.values.whichDose !== "1")) ? (
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <VaccineNameQuestionPediatric
                                                            formik={formik as any}
                                                            disabled={disabled}
                                                            tabIndex={5}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            ) : null}
                                            {(
                                                (notAdult() &&
                                                    (formik.values.whichDose && formik.values.whichDose !== "1")) ||
                                                (
                                                    (doseType === "3" ||
                                                        doseType.substring(0, 7) === "4" ||
                                                        formik.values.vaccineReceived === "yes" || 
                                                        doseType==="2" || 
                                                        formik.values.doseType==="2")
                                                        )) &&
                                                formik.values.vaccineICName === "COVID" && formik.values.whichDose !== "dont-know" &&
                                                (
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            {timeRangeValidation(
                                                                formik,
                                                                ctx
                                                            )}
                                                            <VaccineDateQuestion
                                                                formik={formik as any}
                                                                disabled={disabled}
                                                                tabIndex={8}
                                                            />
                                                            {
                                                                formik.values.vaccineName === "dont-remember" &&
                                                                <Message
                                                                icon="info circle"
                                                                size={"tiny"}
                                                                content={<ReactMarkdown linkTarget="_blank">{ctx.getLabel("unknownDate")}</ReactMarkdown>}
                                                                />
                                                            }
                                                            
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                )}
                                            {formik.values.vaccineICName === "general" && (
                                                <>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <GeneralAppointmentReason
                                                                formik={formik as any}
                                                                disabled={disabled}
                                                                tabIndex={9}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    {formik.values.generalAppointmentReason === 'Other' && (
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <GeneralAppointmentText
                                                                    formik={formik as any}
                                                                    disabled={disabled}
                                                                    tabIndex={9}
                                                                />
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    )}
                                                </>
                                            )}
                                            {ctx.jurisdiction.enableAuthRep && (
                                                <Grid.Row>

                                                    <Grid.Column>
                                                        <IsRepresentativeQuestion
                                                            formik={formik as any}
                                                            disabled={disabled}
                                                            tabIndex={9}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )}
                                            {ctx.clinicID && ctx.clinicType.toLocaleLowerCase() !== "tp" && !(appointmentType && appointmentType !== "Allow both") &&  (
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <ApptTypeQuestion
                                                            formik={formik as any}
                                                            disabled={disabled}
                                                            tabIndex={10}
                                                            clinicTypeID={clinicTypeID}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )}
                                            {
                                                isRoutineVaccine(formik.values.vaccineICName) &&
                                                <>
                                                    {/* <Grid.Row>
                                                        <Grid.Column>
                                                            <WhichRoutineDose
                                                                formik={formik as any}
                                                                disabled={disabled}
                                                                tabIndex={2}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row> */}
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <AtRiskGeneral
                                                                formik={formik as any}
                                                                disabled={disabled}
                                                                tabIndex={10}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </>
                                            }
                                            {
                                                (formik.values.vaccineICName === 'Mpox vaccine') &&
                                                <>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <Message icon="info circle" content={
                                                                <ReactMarkdown linkTarget="_blank">{ctx.getLabel('mpoxNote')}</ReactMarkdown>
                                                            } />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <MpoxDose
                                                                formik={formik as any}
                                                                disabled={disabled}
                                                                tabIndex={10}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    {
                                                        formik.values.mpoxDose === "2" &&
                                                        <>
                                                            <Grid.Row>
                                                                <Grid.Column>
                                                                    <WhichMpoxVaccine
                                                                        formik={formik as any}
                                                                        disabled={disabled}
                                                                        tabIndex={10}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column>
                                                                    <VaccineDateQuestion
                                                                        formik={formik as any}
                                                                        disabled={disabled}
                                                                        tabIndex={8}
                                                                        noMin
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </>
                                                    }
                                                </>
                                            }

                                            {(formik.values.generalAppointmentReason === "COVID-19 testing") && (
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Message
                                                            icon="info circle"
                                                            size={"tiny"}
                                                            content={<ReactMarkdown linkTarget="_blank">{ctx.getLabel("testingInformational")}</ReactMarkdown>}
                                                        />
                                                        <TestLabQuestion
                                                            formik={formik as any}
                                                            disabled={disabled}
                                                            tabIndex={2}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>

                                            )}
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Form.Checkbox
                                                        label={<label style={{fontWeight:'bold', marginTop:'12px'}}>{attestationText()}</label>}
                                                        aria-label={<label style={{fontWeight:'bold'}}>{attestationText()}</label>}
                                                        disabled={disabled}
                                                        checked={formik.values.attestationChecked}
                                                        
                                                        onClick={async () => {
                                                            if (!formik.isValid) {
                                                                const errorFields = {} as any;
                                                                Object.keys(formik.errors).forEach(
                                                                    (key) => (errorFields[key] = true)
                                                                );
                                                                formik.setTouched(errorFields, true);
                                                            }
                                                            formik.setFieldValue(
                                                                "attestationChecked",
                                                                !formik.values.attestationChecked
                                                            );
                                                        }}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            {message && (
                                                <Message
                                                    visible={message ? true : false}
                                                    error={message?.error}
                                                    success={message?.success}
                                                >
                                                    <Message.Header>{message?.header}</Message.Header>
                                                    {message?.body && <p>{message.body}</p>}
                                                </Message>
                                            )}
                                        </Grid>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column textAlign={"right"}>
                                    <Button primary disabled={!formik.values.attestationChecked || (
                                        formik.values.vaccineICName === "mmr" &&
                                        isInvalidMMR(formik.values)
                                    )} type="submit">
                                        {ctx.getLabel("next")}
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
